import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/ds/generate_ext_app_key/';

// import downloadBLOB from '@/assets/downloadBLOB';

function generateExtAppKey(handler, dsUid) {
  fetch(ROOT_URL + REQUEST_LINK + dsUid + '/', {
    method: 'GET',
    credentials: 'include',
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.text());
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка генерации ключа. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });

  // window.open(
  //     ROOT_URL + REQUEST_LINK + dsUid + '/', '_blank',
  // ).focus();
  // handler('ok');

  // // store.commit('set_full_screen_loading', true);
  // fetch(ROOT_URL + REQUEST_LINK + dsUid + '/', {
  //   method: 'GET',
  //   credentials: 'include',
  // }).then(async function(response) {
  //   // const objectTypeData = await response.json();
  //   // store.commit('set_full_screen_loading', false);
  //   if (response.status == 200) {
  //     // const contentType= response.headers.get('content-type');

  //     // console.log('response.headers', response.headers);

  //     let filename = response.headers.get('Content-Disposition');

  //     // console.log('filename', filename);

  //     filename = filename.match(/(?<=")(?:\\.|[^"\\])*(?=")/)[0];

  //     filename = decodeURI(filename);

  //     // console.log('filename', filename);

  //     const blobFile = await response.blob();
  //     // const blob = new Blob([curText], {type: contentType});
  //     const url = window.URL.createObjectURL(blobFile);
  //     // return {'result': {url}, 'is_file': true};

  //     downloadBLOB(url, filename);

  //     // console.log('response', response);

  //     // window.location.assign(url);

  //     handler('ok');
  //   }
  // }).catch((errData) => {
  //   // handler(false);
  // });
}

export default generateExtAppKey;
