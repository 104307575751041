<template>
  <div class="data-source-editor-wraper">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>
    <ChangeObjectForm
                      :raw_fields="this.drawFields"
                      :raw_actions="this.drawActions"
                      @completeFormAction="this.saveEA"
                    />
  </div>
</template>

<script type="text/javascript">
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelComponent from '@/units/RichLabel.vue';

import addNewExtApp from '@/assets/dataSources/subscription/addNewExtApp.js';

export default {
  components: {
    ChangeObjectForm,
    LabelComponent,
  },
  data: () => ({
    savingError: null,
    isSaving: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    saveEA(saveForm) {
      if (this.isSaving) return;

      this.savingError = null;
      this.isSaving = true;

      addNewExtApp(async (result) => {
        this.isSaving = false;

        if (result._error == true) {
          if (result.status == null) this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.savingError = `${result.text} Статус: ${result.status}`;
          return;
        }

        // await window.openAcceptWindow('СОХРАНИТЕ КЛЮЧ ДЛЯ ПОДКЛЮЧЕНИЯ РОБОТА!\nПосле перезагрузки страницы этот ключ получить будет невозможно!\n' + result.text);

        this.$emit('result', 'ok');
      }, saveForm);
    },
  },
  computed: {
    ea_uid() {
      return this.params.ea_uid;
    },
    rel_user_uid() {
      return this.params.rel_user_uid;
    },
    initial_name() {
      return this.params.initial_name == null ? '' : this.params.initial_name;
    },
    drawFields() {
      return [
        {
          'type': 'text',
          'view': 'ea_uid',
          'system_name': 'ea_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true,
          'value': this.ea_uid,
        },
        {
          'type': 'text',
          'view': 'Привязанный пользователь',
          'system_name': 'rel_user',
          'nullable': false,
          'readonly': false,
          'is_hidden': true,
          'value': this.rel_user_uid,
        },
        {
          'type': 'text',
          'view': 'Наименование',
          'system_name': 'name',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'value': this.initial_name,
        },
      ];
    },
    drawActions() {
      return [
        {
          system_name: 'save',
          view: 'Сохранить',
          is_loading: this.isSaving,
        },
      ];
    },
  },
};
</script>
