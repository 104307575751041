import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/ds/delete_ext_app/';

function deleteExtApp(deleteUid, handler) {
  const delForm = new FormData();
  delForm.append('ea_uid', deleteUid);

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: delForm,
  }).then(async function(response) {
    if (response.status == 200) {
      handler('ok');
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка удаления источника. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default deleteExtApp;
